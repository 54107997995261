var emi;
var amount = document.querySelector('.amount-bar');
var init = new Powerange(amount, {
  min: 5000,
  max: 50000,
  start: 15000,
  step: 1000,
  hideRange: true
});
var month = document.querySelector('.month-bar');
var initMonth = new Powerange(month, {
  min: 3,
  max: 24,
  start: 12,
  step: 3,
  hideRange: true
});
amount.onchange = function() {
  document.getElementById('js-display-change').innerHTML = amount.value + ' ZAR';
  emi = EMI(10.5, month.value, amount.value).toFixed(2);
  document.getElementById('amountFinal').innerHTML = emi;
  console.log('EMI ' + emi);
};
month.onchange = function() {
  document.getElementById('js-display-change-month').innerHTML = month.value + ' Month(s)';
  emi = EMI(10.5, month.value, amount.value).toFixed(2);
  document.getElementById('amountFinal').innerHTML = emi;
  console.log('EMI ' + emi);
};

function EMI(interest, months, amount) {
  /*
  payment = principle * monthly interest/(1 - (1/(1+MonthlyInterest)*Months))
  */
  var yearlyInterest = ((interest / 100) / 12);
  return Math.round(amount * yearlyInterest / (1 - (Math.pow(1 / (1 + yearlyInterest), months))));

}

function isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    } else {
        return true;
    }
}
